.process-color {
  color: #e48360;
}
.bloc-item {
  position: relative;
}
.toggler-collapse {
  border: 1px solid #e48360;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0;
  top: 7px;
  cursor: pointer;
}
.arrow {
  border-top: 1px solid #e48360;
  border-left: 1px solid #e48360;
  transform: rotate(45deg);
  width: 6px;
  height: 6px;
  margin-left: 6px;
  margin-top: 7px;
}
.display-none {
  display: none;
}
.rotate {
  transform: rotate(180deg);
}
/*# sourceMappingURL=src/pages/process/SuiviDesDemenagements/fiche.css.map */